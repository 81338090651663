<template>
    <div id="result" class="col-md-12">
        <h5>{{ $t('Key figures') }}</h5>
        <div class="row">
            <div class="col-sm-6 col-md-4">
                <label>P10</label>
                <input class="form-control form-control-sm" v-model="props.P10" id="P10" readonly />
            </div>
            <!-- <div class="col-sm-6 col-md-4"> -->
                <!-- <label>P50</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.P50" id="P50" readonly /> -->
            <!-- </div> -->
            <!-- <div class="col-sm-6 col-md-4"> -->
                <!-- <label>P70</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.P70" id="P70" readonly> -->
            <!-- </div> -->
            <!-- <div class="col-sm-6 col-md-4 mt-2"> -->
                <!-- <label>P90</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.P90" id="P90" readonly> -->
            <!-- </div> -->
            <!-- <div class="col-sm-6 col-md-4 mt-2"> -->        
                <!-- <label>{{ $t('Minimum') }}</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.Minimum" id="Min" readonly> -->
            <!-- </div> -->    
            <!-- <div class="col-sm-6 col-md-4 mt-2"> -->
                <!-- <label>{{ $t('Maximum') }}</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.Maximum" id="Max" readonly> -->
            <!-- </div> -->    
            <!-- <div class="col-sm-6 col-md-4 mt-2"> -->
                <!-- <label>{{ $t('Average') }}</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.Mean" id="Mean" readonly> -->
            <!-- </div> -->
            <!-- <div class="col-sm-6 col-md-4 mt-2"> -->
                <!-- <label>{{ $t('Standard deviation') }}</label> -->
                <!-- <input class="form-control form-control-sm" v-model="existingMontecarloData.StDev" id="StDev" readonly> -->
            <!-- </div> -->        
        </div>
    </div>
</template>


<script setup lang="ts">
    const props = defineProps({
        P10: Number,
        P50: Number,
        P70: Number,
        P90: Number,
        Minimum: Number,
        StDev: Number
    });
</script>